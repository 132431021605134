let hello = true;

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR");
  });
}


// CSS Imports
import '../css/style.pcss';

// NPM Imports
import Alpine from 'alpinejs';

window.Alpine = Alpine;
Alpine.start();

